#popular-cat .slick-list {
  /* margin-bottom: -320px; */
}
#popular-cat .slick-prev,
#popular-cat .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 35%;
  display: block;
  width: 35px;
  height: 35px;
  margin-top: auto;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  border-radius: 50% !important;
  z-index: 999;
}
#popular-cat .slick-slide img {
  height: 150px;
}
section.new-in-business .slick-slide {
  margin: 10px;
}
#local-highlight .slick-slide {
  width: 400px !important;
}

.suggestions {
  background-color: #fff;
  width: 100%;
  /* margin: 0px 0px 0px 332px; */
  position: absolute;
  top: 58px;
  padding: 0;
  max-height: 13rem;
  z-index: 999;
  overflow: auto;
}
.suggestions li {
  list-style-type: none;
  text-align: left;
  cursor: pointer;
  padding: 5px 20px;
}

.suggestions li:hover {
  background-color: #efefef;
}

.moving_vertical {
  animation: vertical 0.8s infinite;
  animation-duration: 10s;
}
.boost-business-dark-left,
.boost-business-dark-right {
  display: flex;
  position: relative;
  margin-top: 6px;
  background-color: black;
  border-bottom: 2px solid #828282;
  border-radius: 10px;
  padding: 10px;
}
.boost-business-dark-left img,
.boost-business-dark-right img {
  width: 60px;
}
.boost-business-dark-left h4,
.boost-business-dark-right h4 {
  margin-bottom: 2px;
}

.boost-business-dark-right {
  text-align: right;
}
.boost-business-dark-right .relative-div {
  position: relative;
}
.boost-business-dark-left .hr-green,
.boost-business-dark-right .hr-green {
  width: 15%;
  background: #349c52;
  border-radius: 5px;
  height: 4px;
  opacity: 1;
  margin: 6px 0;
}

.boost-business-dark-right .hr-green {
  text-align: right;
  position: absolute;
  right: 0;
  margin-top: 5px;
}

.boost-business-dark-left h4,
.boost-business-dark-right h4 {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}
.boost-business-dark-left p,
.boost-business-dark-right p {
  margin: 3px 0;
  color: #fff;
  font-size: 15px;
  opacity: 70%;
}
/* .byb-img-resposive{
max-width: 100%;
} */
.img62-new {
  position: absolute;
  width: 50px;
  left: -36px;
  bottom: 50%;
}
.event-explore-btn {
  width: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #349c52;
  padding: 16px;
  border-radius: 12px;
  position: relative;
}
/* .event-explore-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
} */
.event-explore-logo {
  width: 200px;
}
.event-explore-gift {
  width: 300px;
  position: absolute;
  left: 80%;
  top: 50%;
  transform: translate(-50%, -60%);
  z-index: 2;
}
.HomeGiftIcon {
  width: 30%;
  display: block;
  position: relative;
}
.HomeGiftTextWrapper {
  width: 50%;
  text-align: left;
  color: #ffffff;
  padding-left: 16px;
}
.events-pop-title {
  font-size: 30px;
  font-weight: 800;
  color: #ffffff;
}
.events-pop-title-wrap {
  width: 50%;
  text-align: left;
}
.events-pop-gift-wrap {
  width: 30%;
  display: block;
}
.event-invest-wrap {
  width: 30%;
}
.event-explore-btn-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8rem;
}
.sticker_home {
  position: absolute;
  width: 15%;
  top: 80%;
  left: 15%;
  transform: translate(-50%, -80%);
  z-index: 3;
}
.red-fade_home {
  position: absolute;
  width: 30%;
  top: 90%;
  left: 15%;
  transform: translate(-50%, -80%);
  z-index: 2;
}
.bookTicketBtn_home {
  padding: 8px 16px;
  border-radius: 6px;
  display: inline-block;
  font-size: 1rem;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;
  background-color: #ffffff;
  color: #df3c31;
  position: absolute;
  top: 85%;
  left: 15%;
  transform: translate(-50%, -80%);
  z-index: 4;
}

@keyframes vertical {
  0%,
  100% {
    transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateY(-5px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateY(5px);
  }
}

.moving_horizontal {
  animation: horizontal 0.8s infinite;
  animation-duration: 10s;
}

@keyframes horizontal {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-5px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(5px);
  }
}
@media (max-width: 768px) {
  .byb-img-resposive {
    max-width: 16rem;
  }
  .event-explore-logo {
    width: 130px;
  }
  .event-explore-gift {
    width: 190px;
  }
  .events-pop-title {
    font-size: 18px;
  }
  .event-explore-btn {
    width: 560px;
  }
}
@media (max-width: 450px) {
  .event-explore-logo {
    width: 125px;
  }
  .event-explore-gift {
    width: 140px;
    top: 63%;
    left: 160%;
  }
  .events-pop-title {
    font-size: 20px;
  }
  .event-explore-btn-wrap {
    margin-top: 4rem;
  }
  .sticker_home {
    width: 30%;
    top: 95%;
    left: 20%;
  }
  .bookTicketBtn_home {
    top: 100%;
    left: 25%;
  }
  .red-fade_home {
    top: 100%;
    left: 20%;
  }
  .invest-fest-Wrapper {
    margin: 1rem 0;
    display: inline-block;
  }
  .event-explore-btn {
    width: 100%;
    flex-direction: column;
  }
  .HomeGiftIcon {
    width: 12%;
  }
  .HomeGiftTextWrapper {
    width: 60%;
  }
}
