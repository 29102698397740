.footer_animatio_img {
  display: flex;
  align-items: center;
  justify-content: end;
  position: relative;
}

.footer_animation_slide_wrap {
  position: absolute;
  left: -20px;
  top: 2px;
}
.slide_right,
.slide_left {
  width: 21rem;
}
.slide_left-1 {
  width: 22rem;
}
.exp-download-btns2 button {
  background: #292828;
  border: 1.5px solid #ffffff;
  border-radius: 7px;
  margin: 24px 12px 10px 0px;
  padding: 7px 14px 6px 14px;
  -webkit-font-smoothing: auto;
}
.exp-download-btns2 button .btn-txt1 p {
  color: #fff;
  font-weight: 600;
  font-size: 19px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.exp-download-btns2 button .btn-img1 img {
  width: 30px;
  padding: 6px 0px;
}
.exp-download-btns2 button:hover {
  background: #349c52;
  transition: 0.5s;
}
.relative {
  position: relative;
}
.green-label-footer {
  width: 100%;
  position: absolute;
  bottom: 8%;
  left: 0;
}
.banner {
  width: 100%;
  background-color: #349c52;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  padding: 10px 0;
  transform: rotate(-2deg);
}

.text {
  display: inline-block;
  color: white;
  font-size: 1em;
  font-weight: 500;
  font-style: italic;
}
.label-text {
  padding-left: 4rem;
}
.label-text:nth-child(1) {
  padding-left: 0px;
}
@media (max-width: 767px) {
  .green-label-footer {
    width: 100%;
    position: absolute;
    bottom: 5%;
    left: 0;
  }
}
@media (max-width: 450px) {
  .slide_right,
  .slide_left {
    width: 16rem;
  }
  .green-label-footer {
    width: 100%;
    position: absolute;
    bottom: 7%;
    left: 0;
  }
}
