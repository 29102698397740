.password-input-container {
  position: relative;
}

.password-toggle-btn {
  position: absolute;
  right: 16px;
  top: 40%;
  transform: translateY(-50%);
  border: 0px;
}



.eyes-icon {
  width: 24px;
}

/* .login_first {
  background-image: url("../../assets/images/login-back.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.pop_header {
  width: 100%;
  background-color: black;
  padding-top: 15px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #349c52;
}
.login_form_wrap {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-top: 9rem;
}
.login_form {
  width: 50%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #349c52;
  padding-right: 3rem;
}
.dwnld_app2 {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login_form_label {
  font-size: 20px;
  font-weight: 800;
  color: #349c52;
}
.login_form_email {
  color: #fff;
  font-size: 15px;
  font-weight: 300;
  display: block;
  margin-bottom: 7px;
}
.login_form_input {
  width: 100%;
  background: #0d0d0f;
  border: 1px solid #222222;
  padding: 12px 17px;
  margin-bottom: 14px;
  color: #fff;
  border-radius: 7px;
}
.login_form_btn {
  background: #349c52;
  border: 2px solid #349c52;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  border-radius: 7px;
  width: 100%;
  padding: 12px 0;
  margin-top: 10px;
  text-decoration: none;
  display: inline-block;
}
.dwnld_app2_span,
.dwnld_app2_h3 {
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  line-height: 31px;
  margin-bottom: 16px;
}
.dwnld_app2_span font {
  color: #349c52;
}
.download_btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 1rem;
  margin-left: 1.5rem;
}
.g_play_btn_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 8px;
}
.g_play_btn {
  border: none;
  outline: none;
  border-radius: 8px;
  background: transparent;
  border: 1px solid white;
}
.g_play_btn:hover {
  background: #349c52;
  color: white;
}
.btn_txt {
  text-align: left;
  padding-left: 16px;
}
.btn_text_span {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
}
.btn_text_p {
  margin: 0px;
  font-size: 17px;
  font-weight: 600;
  margin-top: -5px;
  color: #fff;
}
.dwnld_app2_instr {
  color: #349c52;
  background: #349c521c;
  font-size: 16px;
  padding: 0px;
  margin-top: 15px;
  border-radius: 5px;
}

@media only screen and (max-width: 1260px) {
  .login_form_wrap {
    width: 80%;
  }
}

@media only screen and (max-width: 1024px) {
  .login_form_wrap {
    width: 90%;
  }
}

@media only screen and (max-width: 768px) {
  .login_form_wrap {
    width: 100%;
    padding-top: 10px;
  }
  .login_form {
    border-right: 0;
    padding-top: 20px;
    padding-right: 0;
    width: 90%;
  }
  .dwnld_app2 {
    padding-left: 0;
    width: 100%;
  }
} */
