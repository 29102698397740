button#login-btn {
  color: #349b52 !important;
  font-weight: 500 !important;
  border: 1px solid #349b52;
  padding: 9px 35px !important;
  background-color: transparent;
}

.br-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}
select {
  color: #000
}
select.placeholder-shown {
  color: grey;
}
select:disabled {
  color: #9e9e9e !important;
}
/* .br-header .form-search{
  width: 38%;
} */
@media (max-width: 768px) {
  .br-header .form-search{
    width: 100%;
  }
}