.select_box {
  margin: 0 auto;
  position: relative;
  min-width: 16rem;
}
.select_box select {
  display: none;
}

.selectbox_btn {
  padding: 14px;
  background-color: #fff;
  /* box-shadow: 0px 2px 6px -2px rgb(23 43 77 / 16%) !important; */
  /* border-radius: 4px; */
  /* border: 0.5px solid #dfe1e6; */
  font-size: 1rem;
  font-weight: 500;
  color: #172b4d;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  /* height: 2.66rem; */
}
.br-header .selectbox_btn{
  height: 36px;
}

.selection-active,
.selectbox_btn:focus,
.selectbox_btn:active,
.selectbox_btn:focus-visible {
  /* border: 1px solid #eeee; */
  /* outline: none; */
}
.selectbox_btn img {
  position: absolute;
  right: 8px;
}

.selectbox_content {
  position: absolute;
  margin-top: 2px;
  background-color: #fff;
  box-shadow: 0px 2px 7px 0px rgb(23 43 77 / 12%);
  border: 0.5px solid #bcceed;
  min-width: 16rem;
  text-align: left;
  z-index: 99;
  max-height: 10rem;
  overflow: auto;
}

.selectbox_content .selectbox_item {
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  color: #121212;
  transition: all 0.2s !important;
}

.selectbox_content .selectbox_item.select_active {
  background-color: #ffff;
}

.selected_title {
  color: #121212;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 12rem;
  text-align: left;
}

.select_items option {
  word-break: break-all;
}
.select_items option {
  padding: 8px 14px;
}
.select_items option:hover {
  background-color: #c4e4cd;
}

::-webkit-scrollbar {
  width: 5px;
}
